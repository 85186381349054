import React from "react";
import Image from "next/image";
import router from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import OopsIcon from "assets/images/oops.png";
import { BigCta } from "components/big-cta";
import { Button } from "components/button";
import { Footer } from "components/footer";
import { NavBar } from "components/navbar";
import { MainContainer } from "containers/main-container";
import { Meta } from "seo/meta";
import { Banner2 } from "styles/banners";
import { ParagraphText, TextHeadingOne } from "styles/typography";

export default function Page404() {
   return (
      <Meta title="Page Not Found" description="Page Cannot be found.">
         <MainContainer>
            <NavBar />
            <div style={{ marginTop: 100 }}></div>
            <Banner2>
               <Image src={OopsIcon} alt="Page Not Found" />
               <TextHeadingOne mw="663" center>
                  404 - PAGE NOT FOUND
               </TextHeadingOne>
               <ParagraphText center>
                  Maybe this page is working from home? Let's go find out.
               </ParagraphText>
               <br />
               <Button onClick={() => router.push("/")} variant="primary">
                  Go to home page
               </Button>
            </Banner2>
            <BigCta />
         </MainContainer>
         <Footer />
      </Meta>
   );
}

export async function getStaticProps({ locale }) {
   return {
      props: {
         ...(await serverSideTranslations(locale, ["common", "footer"])),
         // Will be passed to the page component as props
      },
   };
}
